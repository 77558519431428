import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { VixenUserState } from '../../types'

import { getTranslatableUserRole } from './getTranslatableUserRole'
import type { UserRoleProps } from './types'

/**
 * Return a single display name for a user role
 */
export const UserRole = memo(
  ({ groups, userState = VixenUserState.ACTIVE }: UserRoleProps) => {
    const { t } = useTranslation()
    const bestRole = getTranslatableUserRole({ groups, userState })
    return <>{t(`identify.user-role.${bestRole}`, '?')}</>
  },
)
