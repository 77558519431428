import { VixenUserState } from '../../types'
import { rolesInOrder } from '../constants'

import type { UserRoleProps } from './types'

/**
 *
 * Export out a single display name for a user role
 *
 * @param groups An array of group names
 * @returns the best role to display for the user
 */
export const getTranslatableUserRole = ({
  groups,
  userState,
}: UserRoleProps): string => {
  switch (userState) {
    case VixenUserState.DELETED:
      return 'deleted'

    case VixenUserState.DISABLED:
      return 'disabled'
    default:
      break
  }

  const roles = new Set(groups)
  const bestRole = (rolesInOrder.find(elem => roles.has(elem)) ?? 'none')
    // handle colons in Vixen group names
    .replace(':', '-')

  return bestRole
}
