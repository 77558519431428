import { VixenGroups } from '../types'

/** A list of roles in order of display priority */
export const rolesInOrder = [
  VixenGroups.ADMINS,
  VixenGroups.PUBLISHERS,
  VixenGroups.EDITORS,
]

export enum IdentifyAnalyticsMeta {
  'CLIENT' = 'Pladia Eventsync Client',
  'PROJECT' = 'Pladia Eventsync Project',
  'ENVIRONMENT' = 'Pladia Eventsync Environment',
  'VERSION' = 'Pladia Eventsync Version',
  'LANGUAGE' = 'Pladia Eventsync Language',
  'ROLE' = 'Pladia Eventsync Role',
}

export const analyticsEnvironment = {
  [IdentifyAnalyticsMeta.CLIENT]: import.meta.env.VITE_PLADIA_CLIENT,
  [IdentifyAnalyticsMeta.PROJECT]: import.meta.env.VITE_PLADIA_PROJECT,
  [IdentifyAnalyticsMeta.ENVIRONMENT]: import.meta.env.VITE_PLADIA_DEPLOY_ENV,
}

export enum AnalyticsTypenames {
  USER = 'User',
}

export enum IdentifyQueryKeys {
  AuthUser = 'identify:authUserQuery',
  CurrentSession = 'identify:currentSessionQuery',
}
