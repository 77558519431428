import { useEffect } from 'react'

import type { HubCapsule } from '@aws-amplify/core'
import { Hub } from '@aws-amplify/core'
import { useQueryClient } from '@tanstack/react-query'

import { IdentifyQueryKeys } from './constants'

import { setSentryIdentifiers } from 'utils/sentry'

/**
 * A subscriber that listens to the auth hub and invalidates the auth user query
 * when the auth state changes.
 *
 * This hook should only be mounted once, at the root of the application.
 */
export const AuthMonitor = (): null => {
  const queryClient = useQueryClient()

  // Updates the query based on the hub events
  useEffect(() => {
    const handleAuthEvent = (data: HubCapsule): void => {
      switch (data.payload.event) {
        case 'signIn':
        case 'tokenRefresh':
          queryClient.invalidateQueries({
            queryKey: [IdentifyQueryKeys.AuthUser],
          })
          queryClient.invalidateQueries({
            queryKey: [IdentifyQueryKeys.CurrentSession],
          })
          break

        case 'signOut':
        case 'signIn_failure':
        case 'tokenRefresh_failure':
          queryClient.removeQueries({
            queryKey: [IdentifyQueryKeys.AuthUser],
          })
          queryClient.removeQueries({
            queryKey: [IdentifyQueryKeys.CurrentSession],
          })

          // FIXME: Disable Mixpanel for the moment
          // mixpanel.reset()

          setSentryIdentifiers(undefined)
          break

        default:
          console.info('Unhandled Auth Monitor event', data)
          break
      }
    }

    const unsubscribe = Hub.listen('auth', handleAuthEvent)

    return () => {
      unsubscribe()
    }
  }, [])
  return null
}
