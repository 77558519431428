import styled, { css, keyframes } from 'styled-components'

import type { ThemeColor } from 'theme/theme'
import { theme, space, pxToRem, color as themeColor } from 'theme/utils'
import type { MakeStyledTransientProps } from 'types/utils'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div<
  MakeStyledTransientProps<Pick<LoaderProps, 'variant'>>
>`
  --color: ${themeColor('text')};
  display: flex;
  flex-direction: column;
  ${({ $variant }) =>
    $variant === 'light' &&
    css`
      --color: ${themeColor('grey50')};
    `}
`
type SpinnerProps = MakeStyledTransientProps<
  Pick<LoaderProps, 'size' | 'color' | 'backgroundColor'>
>

export const Spinner = styled.span<SpinnerProps>`
  --size: ${pxToRem(16)};
  --border-width: ${pxToRem(3)};

  margin: 0 auto;
  width: var(--size);
  height: var(--size);
  border: var(--border-width) solid black;
  border-radius: 100%;
  display: inline-block;
  border-color: var(--spinner-color) var(--spinner-color) var(--spinner-color)
    var(--spinner-background-color);
  animation: ${spinAnimation} 2s infinite linear;
  transition: ${theme('transition.base')};

  ${({ $color, $size, $backgroundColor }) => css`
    ${$color &&
    css`
      --spinner-color: ${themeColor($color)};
    `}
    ${$backgroundColor &&
    css`
      --spinner-background-color: ${themeColor($backgroundColor)};
    `}
    ${$size === 'xSmall' &&
    css`
      --size: ${pxToRem(12)};
      --border-width: ${pxToRem(2)};
    `}
    ${$size === 'small' &&
    css`
      --size: ${pxToRem(15)};
    `}
    ${$size === 'medium' &&
    css`
      --size: ${pxToRem(20)};
    `}
    ${$size === 'large' &&
    css`
      --size: ${pxToRem(32)};
      --border-width: ${pxToRem(4)};
    `}
        ${$size === 'xLarge' &&
    css`
      --size: ${pxToRem(60)};
      --border-width: ${pxToRem(7)};
    `}
  `};
`

const Text = styled.strong`
  margin-top: ${space(3)};
  text-transform: uppercase;
  display: block;
  color: var(--color);
  font-weight: ${theme('font.weight.bold')};
  font-size: ${theme('font.size.small')};
  text-align: center;
`

export type LoaderProps = {
  text?: string
  variant?: 'light' | 'dark'
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
  color?: ThemeColor
  backgroundColor?: ThemeColor
}

/**
 * Loader input component is used to display when you are processing/loading/updating something.
 * *
 * The optional `text` prop can be used to provide textual information beneath the loading indicator
 *
 * The optional `variant` prop applies color change to the option supplied `text`.
 */

export const Loader = ({
  text,
  size = 'medium',
  color = 'blue60',
  backgroundColor = 'border',
  variant = 'dark',
}: LoaderProps): JSX.Element => (
  <Container $variant={variant}>
    <Spinner $size={size} $color={color} $backgroundColor={backgroundColor} />
    {text && <Text>{text}</Text>}
  </Container>
)
