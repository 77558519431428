import type { QuerySideEffectRegistration } from './types'

/**
 * Type safe way to create a registration for a react-query success.
 *
 * @param key The react-query key.
 * @param effectFn The function to call when the query is successful, it will
 * be called once for each query that matches the key.
 * @returns A Typed QuerySideEffectRegistration.
 */
export function defineQuerySideEffectRegistration<TData>(
  key: string,
  effectFn: (data: TData) => void,
): QuerySideEffectRegistration {
  return {
    key,
    effectFn,
  }
}
