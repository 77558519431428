import { createGlobalStyle, css } from 'styled-components'

import { BASE_FONT_SIZE } from './constants'

import { fontSize, fontWeight, color, fontFamily } from 'theme/utils'

export const fullHeight = css`
  height: 100%;
  height: 100vh;
  height: 100dvh;
`

export const fullMaxHeight = css`
  max-height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
`

export const fullMinHeight = css`
  min-height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
`

export const srOnlyMixin = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, #root {
    ${fullMinHeight}
  }

  #root {
    display: flex;
    width: 100%;
  }

  html {
    font-size: ${BASE_FONT_SIZE}px;
    overflow-x: hidden;

    &:has([data-disable-overscroll="x"]) {
      overflow-x: hidden !important;
      overscroll-behavior-x: none;
    }

    &:has([data-disable-overscroll="y"]) {
      overflow-y: hidden !important;
      overscroll-behavior-y: none;
    }

    &:has([data-disable-overscroll="xy"]) {
      overflow: hidden !important;
      overscroll-behavior: none;
    }
  }

  body { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    
    font-family: ${fontFamily('base')};
    font-weight: ${fontWeight('regular')};
    color: ${color('black')};

    background: #1D1C1D;
  }

  figure, fieldset {
    padding:0;
    margin:0;
  }

  fieldset{
    border: 0;
  }

  button, a {
    // Prevents a tap highlight on mobile
    -webkit-tap-highlight-color: transparent;
  }
  
  button {
    cursor: pointer;
    border: 0;
    appearance: none;
    background: none;
  } 
  
  li {
    margin: 0 0 1rem 0;
  }

  a {
      color: ${color('blue60')};
      text-decoration: none;
  }

  ul, ol {
    margin: 0 0 1rem 0;
    padding: 0 0 0 1rem;
  }

  p, h1, h2, h3, h4, h5, h6 {
    color: ${color('black')};
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6,
  .h1,.h2,.h3,.h4,.h5,.h6 {
    font-weight: ${fontWeight('semibold')};
    line-height: 1.2;
  } 

  h1 {
    font-size: ${fontSize('xLarge')};
  }

  h2, .h2 {
    font-size: ${fontSize('large')};
  }

  h3,.h3 {
    font-size: ${fontSize('medium')};
  }
  
  p {
    font-size: ${fontSize('base')};
    font-weight: ${fontWeight('regular')};
    line-height: 1.26;
    color: ${color('grey70')}
  }

  strong, b {
    font-weight: ${fontWeight('bold')};
  }

  // Selector to hide something from the screen so it only shows up in the
  // screen reader
  [data-sr-only] {
    ${srOnlyMixin}
  }
`
