import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components'

import { Routes } from './router/Routes'
import { AuthMonitor } from './views/login/components'

import { GlobalStyle } from 'theme/global'
import { themeValues } from 'theme/theme'
import getEnv from 'utils/getEnv'

const env = getEnv()

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // TODO - enable side effects!
    // onSuccess: (data, query) => {
    //   // We can register side effects for queries in modules
    //   // and then when the query is successful, we go through
    //   // the registered queries, find the ones that match the key
    //   // of the completed query, then call the callback function
    //   // with the data for that query.
    //   // This mechanism helps run side effects for queries that are not
    //   // tied to the execution of the components that called the useQuery.
    //   const sideEffects = MODULES.all().flatMap(({ querySideEffects }) =>
    //     querySideEffects
    //       ? querySideEffects.filter(sideEffect =>
    //         query.queryKey.includes(sideEffect.key),
    //       )
    //       : [],
    //   )
    //
    //   for (const sideEffect of sideEffects) {
    //     sideEffect.effectFn(data)
    //   }
    // },
  }),
})

export const App = (): JSX.Element => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <HelmetProvider context={{}}>
      <Helmet
        // Set lang to the current UI language
        // N.B. content fields will be set to the appropriate content
        // language as required
        htmlAttributes={{ lang: language }}
        titleTemplate={`%s - ${env.VITE_APP_TITLE}`}
        defaultTitle={env.VITE_APP_TITLE}
      />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeValues}>
          <GlobalStyle />
          <Routes />
          <Toaster
            toastOptions={{
              duration: 4000,
              position: 'bottom-center',
              style: {
                background: 'none',
                maxWidth: '950px',
                width: '100%',
                padding: 0,
                margin: 0,
              },
            }}
          />
        </ThemeProvider>
        <AuthMonitor />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  )
}
