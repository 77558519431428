import getEnv from 'utils/getEnv'
import type { ConfigOptions } from 'utils/settings'

const {
  VITE_APP_DEFAULT_LANGUAGE: defaultLanguage,
  VITE_APP_LANGUAGES: languages,
  VITE_APP_VX_CONFIG: vxConfig,
} = getEnv()

export default {
  // Default language if we can't detect their language via react-i18next
  defaultLanguage,
  // Languages that have UI label translations (stored in /locales) and content
  // and are displayed in the language selector.
  //
  // If only one language is specified, the language selector is not displayed.
  // Case matters in these language codes (zh-Hans, en-AU, etc.)
  languages,

  // --- Behaviour ---
  // - Override bundle config:
  vxConfig,
  helpCentreUrl:
    'https://support.pladia.io/en/articles/9940254-event-sync-configuration-guide',
} as ConfigOptions
