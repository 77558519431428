import type { useMutation, useQuery } from '@tanstack/react-query'

import { QueryDataStatus } from 'store/types'

/**
 * Convert a combination react-query statuses to our own status
 * @param status react-query statuses
 * @param errors react-query errors
 * @returns our own status
 */
export const combinedQueryStatus = (
  status: Array<
    | ReturnType<typeof useQuery>['status']
    | ReturnType<typeof useMutation>['status']
  >,
  errors: Array<ReturnType<typeof useQuery>['error']>,
): QueryDataStatus => {
  if (status.every(sts => sts === 'idle')) {
    return QueryDataStatus.Idle
  }

  if (status.some(sts => sts === 'error')) {
    if (import.meta.env.DEV) {
      console.error('Error running React Query query:', errors, status)
    }
    return QueryDataStatus.Failed
  }
  if (status.every(sts => sts === 'success')) {
    return QueryDataStatus.Ready
  }
  return QueryDataStatus.Loading
}

/**
 * Convert react-query status to our own status
 * @param status react-query status
 * @param error react-query error
 * @returns our own status
 */
export const queryStatus = (
  status:
    | ReturnType<typeof useQuery>['status']
    | ReturnType<typeof useMutation>['status'],
  error: ReturnType<typeof useQuery>['error'],
): QueryDataStatus => {
  return combinedQueryStatus([status], [error])
}
