import i18next from 'i18next'
import { createRoot } from 'react-dom/client'
// @ts-ignore - This is a valid import
import smartOutline from 'smart-outline'

import './index.css'
import { App } from './App'
import Settings from './settings'

import { i18n } from 'utils/i18n'
import { setupSettings } from 'utils/settings'
import '../node_modules/modern-normalize/modern-normalize.css'

// Initialise settings from settings.ts
setupSettings(Settings)

// Initialise translations for static strings
i18n(Settings.languages, Settings.defaultLanguage).then(() =>
  console.log('Initial language is', i18next.resolvedLanguage),
)

// Initialize smart outline
smartOutline.init()

// Mount router
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
