import { memoize, get } from 'lodash'

import { BASE_FONT_SIZE } from './constants'
import type {
  ThemeFontFunction,
  ThemeFunction,
  ThemeInterfaceKeys,
} from './theme'

const PLANES = {
  base: 0,
  floating: 1,
  under: -1,
  overlay: 100,
  dialog: 200,
  fullscreen: 1000,
}

type Plane = keyof typeof PLANES

const SPACING_MULTIPLIER = 4

export const FLOATING_UI_TOGGLE_SPEED = 50

// This is a hard list of spacings that we want to use in our app.
// We don't want to use any other spacings, so we're going to enforce
// this list by creating a type that can only be one of these values.
type SPACINGS = [
  0, // 0px
  0.25, // 1px
  0.5, // 2px
  1, // 4px
  1.5, // 6px
  2, // 8px
  2.5, // 10px
  3, // 12px
  3.5, // 14px
  4, // 16px
  5, // 20px
  6, // 24px
  7, // 28px
  8, // 32px
  9, // 36px
  10, // 40px
  11, // 44px
  12, // 48px
  14, // 56px
  15, // 60px
  16, // 64px
  18, // 72px
  20, // 80px
  24, // 96px
  28, // 112px
  32, // 128px
]

export type Spacing = SPACINGS[number]

/**
 *
 * So that we can still know what's going on when looking at figma / designs in px values,
 * run this function on any px value to convert it to rem.
 * @param px
 * @returns a rem converted string for consumption
 */
export const pxToRem = (px: number): string => `${px / BASE_FONT_SIZE}rem`

/**
 * This is the signature for functions like padding and margin
 * with multiple overloads that works like padding/margin and
 * makes it clear what each argument is.
 */
interface MultiSpaceFunction {
  /**
   * @param space - The space for all sides
   */
  (space: Spacing): string
  /**
   * @param topAndBottom - The space for top and bottom
   * @param leftAndRight - The space for left and right
   */
  (topAndBottom: Spacing, leftAndRight: Spacing): string
  /**
   * @param top - The space for top
   * @param leftAndRight - The space for left and right
   * @param bottom - The space for bottom
   */
  (top: Spacing, leftAndRight: Spacing, bottom: Spacing): string
  /**
   * @param top - The space for top
   * @param right - The space for right
   * @param bottom - The space for bottom
   * @param left - The space for left
   */
  (top: Spacing, right: Spacing, bottom: Spacing, left: Spacing): string
}

/**
 * This is the multi-overload definition for the space function.
 */
interface SpaceFunction {
  /**
   * This returns the rem suffix by default.
   * @param input - The space that will be multiplied by 4 and converted to rem
   */
  (input: Spacing): string
  /**
   * @param input - The space that will be multiplied by 4 and converted to rem
   * @param suffix - Whether or not to add the rem suffix
   */
  (input: Spacing, suffix: boolean): string
}

/**
 * Space utility that works with a multiple of 4 and returns a rem value.
 *
 * @see {@link padding} for padding
 */
export const space: SpaceFunction = memoize(
  (input: Spacing, suffix: boolean = true) => {
    const result = input * SPACING_MULTIPLIER
    return suffix ? pxToRem(result) : `${result}`
  },
  (input: Spacing, suffix?: boolean) => `${input}${suffix ?? false}`,
)

/**
 * Utility function for padding.
 * It's memoized as there's not point in re-calculating the same values over and over.
 *
 * @see {@link space} for setting `gap`
 */
export const padding: MultiSpaceFunction = memoize(
  (...inputs: Spacing[]): string =>
    inputs.map(input => space(input, true)).join(' '),
  // This is necessary so all of the arguments are considered
  (...args: Spacing[]) => args.join(' '),
)

/** Alias for padding for semantically using it on margin */
export const margin = padding

export const theme = (keys: ThemeInterfaceKeys) => (props: any) =>
  get(props, `theme.${keys}`)

/**
 * The shortand for `theme('color.[color]')`;
 * Usage `${color('grayL45')}`
 * @param key The color key
 * @returns color from theme
 */
export const color: ThemeFunction<'color'> = key => props =>
  get(props, `theme.color.${key}`)

/**
 * Shorthand for `theme('font.family.[key]')`
 * Usage `${fontFamily('base')}`
 * @param key Font family key
 * @returns font family
 */
export const fontFamily: ThemeFontFunction<'family'> = key => props =>
  get(props, `theme.font.family.${key}`)

/**
 * Shorthand for `theme('font.size.[key]')`
 * Usage `${fontSize('large')}`
 * @param key Font size key
 * @returns font size
 */
export const fontSize: ThemeFontFunction<'size'> = key => props =>
  get(props, `theme.font.size.${key}`)

/**
 * Shorthand for `theme('font.weight.[key]')`
 * Usage `${fontWeight('bold')}`
 * @param key Font weight key
 * @returns font weight
 */
export const fontWeight: ThemeFontFunction<'weight'> = key => props =>
  get(props, `theme.font.weight.${key}`)

/**
 * Named planes for the z-index,
 * you can put multiple planes and they will be summed, like:
 * plane('modal', 'aboveFollowingContent')
 */
export const plane = memoize(
  (...planes: Plane[]): number =>
    planes.reduce((acc, p) => acc + PLANES[p], 0),
  (...planes) => planes.join(''),
)
